import { Iprevias, PreviasTypes } from './types'
import { ISharedActions } from '../../utils/shared.types'

export const initialState: Iprevias = {
  loading: false,
  loadingPrevias: false,
  success: null,
  successDoc: null,
  loadingRefresh: null,
  list: [],
  usersDetail: undefined,
  userDetailByCpf: undefined,
  historic: undefined,
  previaById: undefined,
  loadingPreviaById: false,
}

export const previasReducer = (state: Iprevias = initialState, action: ISharedActions): Iprevias => {
  switch (action.type) {
    case PreviasTypes.GET_SERVICES_UNITS:
      return {
        ...state,
        units: action.payload,
      }
    case PreviasTypes.GET_DOCTYPE:
      return {
        ...state,
        docTypes: action.payload,
      }
    case PreviasTypes.UPLOAD_FILE:
      return {
        ...state,
        loading: action.payload.loading,
        file: [...(state.file || []), ...(action.payload.data || [])],
      }
    case PreviasTypes.CREATE_USER:
      return {
        ...state,
        loadingPrevias: action.payload.loadingPrevias,
        user: action.payload.data,
      }
    case PreviasTypes.CREATE_PREVIA:
      return {
        ...state,
        loadingPrevias: action.payload.loadingPrevias,
        success: action.payload.success,
      }
    case PreviasTypes.GET_LIST:
      return {
        ...state,
        list: action.payload,
      }
    case PreviasTypes.SET_MOTIVOS_PADRAO:
      return {
        ...state,
        motivosPadrao: action.payload,
      }
    case PreviasTypes.GET_USERS_DETAIL:
      return {
        ...state,
        usersDetail: action.payload,
      }
    case PreviasTypes.GET_USERS_DETAIL_BY_CPF:
      return {
        ...state,
        userDetailByCpf: action.payload,
      }
    case PreviasTypes.GET_HISTORIC:
      return {
        ...state,
        historic: action.payload,
      }
    case PreviasTypes.GET_DOCS:
      return {
        ...state,
        docs: action.payload,
      }
    case PreviasTypes.REFRESH_DOCS:
      return {
        ...state,
        successDoc: action.payload.success,
        loadingRefresh: action.payload.loading,
      }
    case PreviasTypes.GET_PREVIA_BY_ID:
      return {
        ...state,
        previaById: action.payload.data,
        loadingPreviaById: action.payload.loading,
      }
    case PreviasTypes.SET_DESISTIR:
      return {
        ...state,
        loading: action.payload.loading,
      }
    case PreviasTypes.RESET_STATE:
      return {
        ...initialState,
        success: action.payload.success,
        successDoc: action.payload.success,
      }
    default:
      return state
  }
}
