export const SessionTypes = {
  STORE_SESSION: '@@session/STORE_SESSION',
  STORE_USER_INFOS: '@@session/STORE_USER_INFOS',
}

export interface ISession {
  token: string
  user?: IUser
}

export interface IUser {
  _id: String
  name: String
  cdUsuario: String
  cdUsuarioExterno: String
  funcao: String
  enabled: Boolean
  source: String
  seePreviaCard: Boolean
}

// export interface IUser {
//   _id: String
//   name: String
//   cdUsuario: String
//   cdUsuarioExterno: String,
//   funcao: String,
//   enabled: Boolean,
//   source: String
// }
