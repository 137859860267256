import { HomeEquitySimulation, SimulationPostData } from 'types/HomeEquity'

export const HomeEquityTypes = {
  STORE_SIMULATION: '@@homeEquity/STORE_SIMULATION',
  STORE_SIMULATION_DATA: '@@homeEquity/STORE_SIMULATION_DATA',
}

export interface IHomeEquity {
  currentSimulation?: HomeEquitySimulation
  simulationData?: SimulationPostData
}
