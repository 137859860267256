import { IProducts, IProductsState, ProductsTypes } from './types'
import { ISharedActions } from '../../utils/shared.types'
import { Product } from 'enums/index'

export const initialState: IProductsState = {}

export const getProductIdByName = (name: Product, products: IProducts[]): string => {
  return products.find((e) => e.name === name)?._id || ''
}

export const productsReducer = (state: IProductsState = initialState, action: ISharedActions): IProductsState => {
  switch (action.type) {
    case ProductsTypes.STORE_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: action.loading,
      }
    case ProductsTypes.STORE_CURRENT_SIMULATION:
      return {
        ...state,
        currentSimulation: action.payload,
      }
    default:
      return state
  }
}
