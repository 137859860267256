import React from 'react'
import configureStore from './store'
import Routes from './routes'
import { Provider } from 'react-redux'
import { themeDefaults } from 'shared/material-theme'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AppColors } from 'shared/theme/theme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ptBR from 'date-fns/locale/pt-BR'

const initialState = (window as any).initialReduxState
export const store = configureStore(initialState)

const App: React.FC = () => {
  const theme = createTheme({
    palette: {
      ...themeDefaults.palette,
      primary: {
        main: AppColors.primary,
      },
      // type: appTheme || 'light',
    },
  })

  if (window.innerWidth < 700) {
    window.document.body.style.overflow = 'hidden'
    return <iframe title="ava site" src="https://ava.ademicon.net.br/" width="100%" style={{ height: '100vh' }} />
  }

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
          <Routes />
        </LocalizationProvider>
      </Provider>
    </ThemeProvider>
  )
}

export default App
