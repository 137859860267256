import { IApplicationState } from '.'
import { combineReducers } from 'redux'
import { userReducer } from './user'
import { sessionReducer } from './session'
import { productsReducer } from './products'
import { dealReducer } from './deal'
import { offerReducer } from './offer'
import { homeEquityReducer } from './homeEquity'
import { followUpReducer } from './followUp'
import { negociarReducer } from './negociar'
import { previasReducer } from './previas'
import { peopleReducer } from './people'
import { inadimplenciaReducer } from './inadimplencia'
import { lancesReducer } from './lances'

export const rootReducer = () =>
  combineReducers<IApplicationState>({
    user: userReducer,
    session: sessionReducer,
    products: productsReducer,
    deal: dealReducer,
    offer: offerReducer,
    homeEquity: homeEquityReducer,
    followUp: followUpReducer,
    negociar: negociarReducer,
    previas: previasReducer,
    people: peopleReducer,
    inadimplencia: inadimplenciaReducer,
    lances: lancesReducer,
  })
