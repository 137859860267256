import { Dispatch } from 'redux'

import { getTenantId } from 'services/environment'
import { axiosApi } from 'services/axiosInstance'

import { SimulationData, SimulationProduct } from 'types/Product'

import { IApplicationState } from '..'
import { IProducts, ProductsTypes } from './types'

export const getProducts = () => {
  return async (dispatch: Dispatch, state: () => IApplicationState) => {
    dispatch({ type: ProductsTypes.STORE_PRODUCTS, loading: true })
    const { data } = await axiosApi.get<IProducts>(`tenants/${getTenantId()}/products`)
    dispatch({ type: ProductsTypes.STORE_PRODUCTS, payload: data, loading: false })
  }
}

export const simulateProduct = (dataSimulation: SimulationData, productId: String) => {
  return async (dispatch: Dispatch, state: () => IApplicationState) => {
    const { data } = await axiosApi.post<SimulationProduct>(`products/${productId}/simulations`, dataSimulation)
    dispatch({ type: ProductsTypes.STORE_CURRENT_SIMULATION, payload: data })
  }
}

export const clearSimulate = () => {
  return async (dispatch: Dispatch, state: () => IApplicationState) => {
    dispatch({ type: ProductsTypes.STORE_CURRENT_SIMULATION, payload: undefined })
  }
}
