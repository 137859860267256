import React from 'react'
import ReactDOM from 'react-dom'
// import Hotjar from '@hotjar/browser'
import './shared/external/ademitech-ds.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

// Hotjar.init(Number(process.env.REACT_APP_HOTJARID), Number(process.env.REACT_APP_HOTJARSV), {
//   debug: true,
// })

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

reportWebVitals()
