import { faBars, faBell, faCog, faUser, faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { useSelector } from 'react-redux'
import ademiconLogo from 'assets/ava-web-logo.svg'
import Ui from './app-header.style'
import Align, { Alignment, Direction } from 'components/align'
import { IApplicationState } from 'store'
import { ISession } from 'store/session/types'
import { AppColors } from 'shared/theme/theme'

type AppHeaderProps = {
  isNavbarOpen: boolean
  toogleNavbar: any
}

const AppHeader: React.FC<RouteComponentProps & AppHeaderProps> = ({ toogleNavbar, isNavbarOpen, history }) => {
  const session = useSelector<IApplicationState, ISession>((state) => state.session)
  const [openSettings, setOpenSettings] = useState(false)

  const openPopUp = (url: string) => {
    history.push(url)
    setOpenSettings(false)
  }

  return (
    <Ui.Wrap>
      <div>
        <Ui.Info>
          <Ui.Logo onClick={() => history.push('/')}>
            <img src={ademiconLogo} alt="Ademicon" />
          </Ui.Logo>
          <Ui.MenuButton
            onClick={() => {
              toogleNavbar(!isNavbarOpen)
            }}
          >
            {isNavbarOpen ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faBars} />}
          </Ui.MenuButton>
        </Ui.Info>
        {isNavbarOpen && (
          <Ui.DividerContainer>
            <Ui.Divider />
          </Ui.DividerContainer>
        )}
      </div>
      <Ui.Actions>
        <div>
          <Align alignment={Alignment.center} direction={Direction.vertical}>
            <Ui.Welcome>Bem vindo (a), {session.user?.name || ''}</Ui.Welcome>
          </Align>
        </div>
        <div>
          <Ui.ActionButtons>
            <FontAwesomeIcon icon={faBell} onClick={() => openPopUp('/messages')} />
          </Ui.ActionButtons>
          <Ui.ActionButtons onClick={() => setOpenSettings(!openSettings)}>
            <FontAwesomeIcon icon={faCog} />
          </Ui.ActionButtons>
        </div>
      </Ui.Actions>
      {openSettings && (
        <>
          <Ui.Arrow></Ui.Arrow>
          <Ui.Content>
            <Ui.Items>
              <FontAwesomeIcon style={{ marginLeft: 20 }} icon={faUser} size="2x" color={AppColors.greyDark} />
              <Ui.TextsHelper onClick={() => openPopUp('/user-detail')}>
                <Ui.TextSettings>Perfil</Ui.TextSettings>
                <Ui.TextSettingsSub>Confira suas informações pessoais.</Ui.TextSettingsSub>
              </Ui.TextsHelper>
            </Ui.Items>
            <Ui.Items>
              <FontAwesomeIcon style={{ marginLeft: 16 }} icon={faCog} size="2x" color={AppColors.greyDark} />
              <Ui.TextsHelper onClick={() => openPopUp('/configs')}>
                <Ui.TextSettings>Configurações</Ui.TextSettings>
                <Ui.TextSettingsSub>Controle notificações e outras opções.</Ui.TextSettingsSub>
              </Ui.TextsHelper>
            </Ui.Items>
            <Ui.Items>
              <FontAwesomeIcon style={{ marginLeft: 20 }} icon={faBell} size="2x" color={AppColors.greyDark} />
              <Ui.TextsHelper onClick={() => openPopUp('/messages')}>
                <Ui.TextSettings>Mensagens</Ui.TextSettings>
                <Ui.TextSettingsSub>Exibir todas as notificações.</Ui.TextSettingsSub>
              </Ui.TextsHelper>
            </Ui.Items>
          </Ui.Content>
        </>
      )}
    </Ui.Wrap>
  )
}

export default withRouter(AppHeader)
