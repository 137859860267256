import styled from 'styled-components'
import { APP_HEADER_HEIGHT, APP_NAVBAR_OPEN_WIDTH } from 'shared/constants'

const basePageContainerStyle = {
  Content: styled.div`
    display: flex;
    min-height: calc(100vh - ${APP_HEADER_HEIGHT}px);
    height: auto;
    background: #E6E7E8;
  `,
  Main: styled.div<any>`
    padding: 48px 32px 32px;
    width: ${({ isNavbarOpen }) => isNavbarOpen ? `calc(100% - ${APP_NAVBAR_OPEN_WIDTH}px)` : '100%'};
  `
}

export default basePageContainerStyle
