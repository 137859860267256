import { FollowUp, ListCotas } from 'types/FollowUp'

export const FollowUpTypes = {
  BEGIN_FETCH: '@@followUp/BEGIN_FETCH',
  STORE_FOLLOWUP: '@@followUp/STORE_FOLLOWUP',
  STORE_ACOMPANHAMENTO: '@@followUp/STORE_ACOMPANHAMENTO',
  STORE_COTAS_FOLLOWUP: '@@followUp/STORE_COTAS_FOLLOWUP',
  STORE_ASSEMBLEIA_FOLLOWUP: '@@followUp/STORE_ASSEMBLEIA_FOLLOWUP',
  STORE_ASSEMBLEIA_DETAIL_FOLLOWUP: '@@followUp/STORE_ASSEMBLEIA_DETAIL_FOLLOWUP',
  CLEAR_COTAS_LIST: '@@followUp/CLEAR_COTAS_LIST',
}

export interface IFollowUp {
  list?: FollowUp[]
  listCotas?: ListCotas
  listAssembleia?: any
  assembleiaDetail?: any
  acompanhamento: any[]
  loading: boolean
}
