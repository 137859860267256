import { FollowUpTypes, IFollowUp } from './types'
import { ISharedActions } from '../../utils/shared.types'

export const initialState: IFollowUp = {
  acompanhamento: [],
  loading: false,
}

export const followUpReducer = (state: IFollowUp = initialState, action: ISharedActions): IFollowUp => {
  switch (action.type) {
    case FollowUpTypes.BEGIN_FETCH:
      return {
        ...state,
        loading: true,
      }
    case FollowUpTypes.STORE_FOLLOWUP:
      return {
        ...state,
        list: action.payload,
      }
    case FollowUpTypes.STORE_COTAS_FOLLOWUP:
      return {
        ...state,
        listCotas: action.payload,
      }
    case FollowUpTypes.STORE_ACOMPANHAMENTO:
      return {
        ...state,
        acompanhamento: action.payload,
        loading: false,
      }
    case FollowUpTypes.STORE_ASSEMBLEIA_FOLLOWUP:
      return {
        ...state,
        listAssembleia: action.payload,
      }
    case FollowUpTypes.STORE_ASSEMBLEIA_DETAIL_FOLLOWUP:
      return {
        ...state,
        assembleiaDetail: action.payload,
      }
    case FollowUpTypes.CLEAR_COTAS_LIST:
      return {
        ...state,
        listCotas: action.payload,
        listAssembleia: action.payload,
        assembleiaDetail: action.payload,
      }
    default:
      return state
  }
}
