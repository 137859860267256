import { ISession, SessionTypes } from './types'
import { ISharedActions } from '../../utils/shared.types'

export const initialState: ISession = {
  token: ""
}

export const sessionReducer = (
  state: ISession = initialState,
  action: ISharedActions,
): ISession => {
  switch (action.type) {
    case SessionTypes.STORE_SESSION:
      return {
        ...state,
        token: action.payload
      }
    case SessionTypes.STORE_USER_INFOS:
      return {
        ...state,
        user: action.payload
      }
    default:
      return state
  }
}
