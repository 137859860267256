import React from 'react'
import Ui from './page-wrap.styled'

type PageWrapProps = {}

const PageWrap: React.FC<PageWrapProps> = ({ children }) => {
  return <Ui.Wrap>{children}</Ui.Wrap>
}

export default PageWrap
