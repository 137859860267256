import { DadosCota, DadosCredenciamento, Credenciamento, Comprovante } from 'types/lances'

export const LancesTypes = {
  GET_DADOS_COTA: '@@lances/GET_DADOS_COTA',
  GET_CREDENCIAMENTO: '@@lances/GET_CREDENCIAMENTO',
  SET_CREDENCIAR: '@@lances/SET_CREDENCIAR',
  SET_CANCELAR_LANCE: '@@lances/SET_CANCELAR_LANCE',
  RELOAD_HIST: '@@lances/RELOAD_HIST',
  GET_COMPROVANTE: '@@lances/GET_COMPROVANTE',
  CLEAN_COMPROVANTE: '@@lances/CLEAN_COMPROVANTE',
}

export interface Ilances {
  dadosCota?: DadosCota
  credenciamento?: Credenciamento
  dadosCredenciamento?: DadosCredenciamento[]
  loading?: boolean
  nextStep?: boolean
  error?: string
  backToFirst?: boolean
  comprovante?: Comprovante
  loadingComprovante?: boolean
}
