import React from 'react'
import AppHeader from 'components/app-header'
import PageWrap from 'components/page-wrap'
import Ui from './base-page-container.style'
import AppNavbar from 'components/app-navbar'

const BasePageContainer: React.FC = ({ children }) => {
  const [toggleAppNavbar, setToggleAppNavbar] = React.useState<boolean>(true)

  return (
    <PageWrap>
      <AppHeader isNavbarOpen={toggleAppNavbar} toogleNavbar={setToggleAppNavbar} />
      <Ui.Content>
        <AppNavbar open={toggleAppNavbar}>navbar</AppNavbar>
        <Ui.Main isNavbarOpen={toggleAppNavbar}>{children}</Ui.Main>
      </Ui.Content>
    </PageWrap>
  )
}

export default BasePageContainer
