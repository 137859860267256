import BasePageContainer from 'components/base-page-container'
import React from 'react'
import { Redirect, Route, RouteComponentProps, withRouter } from 'react-router-dom'

import { getUrlHodor } from 'services/environment'
import io from 'socket.io-client'

export const socket = io(getUrlHodor(), {
  transports: ['websocket'],
  autoConnect: false,
  query: {
    token: `bearer ${localStorage.getItem('AVA_SESSION_TOKEN')}`,
  },
})

interface IPrivateRouteProps {
  path: string
  component?: any
  title?: string
  exact?: boolean
}

const PrivateRoute: React.FC<RouteComponentProps<any> & IPrivateRouteProps> = ({
  component: Component,
  title,
  ...props
}) => {
  const token = localStorage.getItem('AVA_SESSION_TOKEN')

  React.useEffect(() => {
    if (!socket.connected) {
      console.warn('Socket was request to connect')
      socket.connect()

      socket.on('connect', () => console.warn(`connected ${socket.connected}`))
      socket.on('connect_error', (error: any) => console.warn(error))
      socket.on('disconnect', (reason: any) => console.warn(reason))

      socket.emit('join', [`message_${JSON.parse(localStorage.getItem('AVA_SESSION')!)?._id ?? ''}`])
      socket.on('pagconsorcio', async (data: any) => {
        // alert('socket was called')
        console.log('Socket was called for: ', data)
      })
    }
  }, [props.location.pathname])

  return (
    <BasePageContainer>
      <Route
        {...props}
        render={(props) =>
          token !== '' && token !== null ? (
            <Component {...{ ...props, title }} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: props.location,
              }}
            />
          )
        }
      />
    </BasePageContainer>
  )
}

export default withRouter(PrivateRoute)
