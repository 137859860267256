import { APP_HEADER_HEIGHT, APP_NAVBAR_OPEN_WIDTH } from 'shared/constants'
import { AppColors, FontWeight, Spacing } from 'shared/theme/theme'
import styled from 'styled-components'

const NavItemIcon = styled.div`
  display: inline-block;
  width: 40px;
  margin: 0 auto;
  text-align: center;
`

const NavItem = styled.div`
  cursor: pointer;
  display: inline-block;
  background: none;
  padding: 0;
  border: 0;
  color: ${AppColors.white};
  font-size: 28px;
  width: 100%;
  margin-bottom: ${Spacing.lg}px;

  span {
    font-size: 16px;
    font-weight: ${FontWeight.semibold};
    display: inline-block;
    margin-left: 30px;
  }
`

const NavSubItem = styled.div<any>`
  display: ${({ open }) => (open ? 'block' : 'none')};

  ${NavItemIcon} {
    font-size: 24px;
  }

  ${NavItem} {
    margin-top: ${Spacing.md}px;
    margin-bottom: 0;
    padding-left: ${Spacing.lg}px;
    x span {
      margin-left: ${Spacing.lg}px;
    }
  }
`

const NavItemArrow = styled.div<any>`
  padding-right: ${Spacing.lg}px;
`

const NavItemWrap = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
  width: 100%;
  padding-left: 24px;
`

const Nav = styled.div<any>`
  max-width: ${({ open }) => (open ? APP_NAVBAR_OPEN_WIDTH : 0)}px;
  min-width: ${({ open }) => (open ? APP_NAVBAR_OPEN_WIDTH : 0)}px;
  display: ${({ open }) => (open ? 'flex' : 'none')};

  background: rgb(69 69 69);
  color: ${AppColors.white};
  min-height: calc(100vh - ${APP_HEADER_HEIGHT}px);
  padding-top: ${Spacing.lg}px;
  flex-direction: column;
  justify-content: space-between;
  height: auto;

  ${NavItem} {
    text-align: ${({ open }) => (open ? 'left' : 'center')};
    span {
      display: ${({ open }) => (open ? 'inline-block' : 'none')};
    }
  }

  ${NavSubItem} {
    background: #454545;
    padding-top: 0;
    padding-bottom: 14px;
    box-shadow: 0px 10px 12px rgb(0 0 0 / 16%);
    ${NavItem} {
      display: ${({ open }) => (open ? 'block' : 'none')};
      padding-left: 0;
    }
  }

  ${NavItemArrow} {
    display: ${({ open }) => (open ? 'block' : 'none')};
  }

  ${NavItemWrap} {
    justify-content: ${({ open }) => (open ? '' : 'center')};
  }
`

const appNavbarStyle = {
  Nav,
  NavItem,
  NavItemIcon,
  NavSubItem,
  NavItemWrap,
  NavItemArrow,
}

export default appNavbarStyle
