import { Deal } from 'types/Deal'
import { NegociarItem, NegociarDetail } from 'types/Negociar'

export const NegociarTypes = {
  GET_ITEMS: '@@negociar/GET_ITEMS',
  GET_DETAIL: '@@negociar/GET_DETAIL',
  GET_DETAIL_INFO: '@@negociar/GET_DETAIL_INFO',
  LOADING_DETAIL_INFO: '@@negociar/LOADING_DETAIL_INFO',
  GET_NEGOCIACOES: '@@negociar/GET_NEGOCIACOES',
}

export interface INegociar {
  loading?: boolean
  items?: NegociarItem[]
  detail?: NegociarDetail
  detailInfo?: Deal
  negociacoes?: any[]
}
