import axios from 'axios'
import { getUrlBifrost, getUrlApi } from 'services/environment'

export const axiosBifrost = axios.create({
  baseURL: getUrlBifrost(),
  headers: {
    Authorization: `Bearer ${localStorage.getItem('AVA_SESSION_TOKEN')}`,
  },
})

export const axiosApi = axios.create({
  baseURL: getUrlApi(),
})

axiosApi.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('AVA_SESSION_TOKEN')}`
    return config
  },
  (error) => Promise.reject(error),
)
