import { DealTypes, IDeal } from './types'
import { ISharedActions } from '../../utils/shared.types'

export const initialState: IDeal = {}

export const dealReducer = (state: IDeal = initialState, action: ISharedActions): IDeal => {
  switch (action.type) {
    case DealTypes.STORE_DEAL:
      return {
        ...state,
        currentDeal: action.payload,
      }
    case DealTypes.STORE_PROPOSAL:
      return {
        ...state,
        proposal: action.payload
      }
    default:
      return state
  }
}
