export const InadimplenciaTypes = {
  GET_INADIMPLENCIA_LOADING: '@@inadimplencia/GET_INADIMPLENCIA_LOADING',
  GET_INADIMPLENCIA: '@@inadimplencia/GET_INADIMPLENCIA_REQUEST',
}

interface Inadimplencia {
  total: number
  clientes: InadimplenciaCliente[]
}

export interface InadimplenciaCliente {
  nmPessoa: string
  total: number
  telefone: string
  idPessoa: string
  cotas: InadimplenciaCota[]
}

export interface InadimplenciaCota {
  CD_Cota: number
  CD_Grupo: string
  DT_Vencimento: string
  VL_PARCELA: number
  ocorrencias: InadimplenciaOcorrencia[]
}

export interface InadimplenciaOcorrencia {
  data: string
  descricao: string
  historico: string
  protocolo: string
}

export interface IInadimplenciaState {
  loading: boolean
  data: Inadimplencia
}
