export const getUrlBifrost = () => {
    return process.env.REACT_APP_API_BIFROST || "https://hmlg-bifrost.ademitech.com.br"
}

export const getUrlApi = () => {
    return process.env.REACT_APP_API || "https://api-hmlg.ademitech.com.br"
}


export const getUrlHodor = () => {
    return process.env.REACT_APP_API_HODOR || "https://hmlg-hodor.ademitech.com.br"
}


export const getTenantId = () => {
    return process.env.TENANT || "60d0db2552fcc900beb502d5"
}
