export type IAppColors = {
  primary: string
  primaryDark: string
  textLight: string
  primaryLight: string
  textRegular: string
  greyDark: string
  link: string
  border: string
  white: string
  shadow: string
  lightGray: string
  borderGray: string
  borderDarkGrey: string
  textDark: string
  backgroundLightGrey: string
  green: string
  yellow: string
}

export const AppColors: IAppColors = {
  primary: '#DA1000',
  primaryDark: '',
  primaryLight: '#FF7259',
  textLight: '#BCBEC0',
  textRegular: '#333333',
  textDark: '#6B6B6B',
  greyDark: '#59595B',
  lightGray: '#F1F1F2',
  backgroundLightGrey: '#E6E7E8',
  link: '#248CE0',
  border: '#BCBEC0',
  borderGray: '#D1D3D4',
  white: '#FFFFFF',
  shadow: '0 6px 8px rgba(0, 0, 0, .2)',
  borderDarkGrey: '#707070',
  green: '#359B3C',
  yellow: '#EFDC11',
}

export const FontWeight = {
  thin: 300,
  regular: 500,
  semibold: 600,
  bold: 700,
}

export const Spacing = {
  xs: 8,
  md: 16,
  lg: 24,
  xlg: 32,
}

export const ScreenSize = {
  min: 1366,
  max: 1920,
}

export const Radius = {
  xs: 4,
  md: 8,
  lg: 16,
  xlg: 24,
}
