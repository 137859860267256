export enum TradeType {
  ComFuro = 'F',
  Diluido = 'R',
}

export enum TradeTypeDescription {
  'F' = 'Com Furo',
  'R' = 'Diluido',
}

export const TradeTypeToDescription = (tradeType: string) => {
  switch (tradeType) {
    case 'F':
      return TradeTypeDescription.F
    case 'R':
      return TradeTypeDescription.R
    default:
      return ''
  }
}

export enum Product {
  Lar = 'Lar',
  HomeEquity = 'Home Equity',
  Servicos = 'Servicos',
}

export enum StillType {
  CASA = 'casa',
  APARTAMENTO = 'apartamento',
  COMERCIAL = 'comercial',
}

export enum InstallmentsType {
  PRICE = 'PRICE',
  SAC = 'SAC',
}

export enum InputIcons {
  PEN = 'PEN',
  CLOSE = 'CLOSE',
  SEARCH = 'SEARCH',
  PHONE = 'PHONE',
}

export enum InternalPipe {
  DADOS_PESSOAIS = 'dados_pessoais',
  DADOS_EMPRESA = 'dados_empresa',
  DADOS_BANCARIOS = 'dados_bancarios',
  DADOS_RESIDENCIAIS = 'dados_residenciais',
  DADOS_SOCIOS = 'dados_socios',
  DADOS_ENDERECO_EMPRESA = 'dados_endereco_empresa',
  DADOS_SEGURO = 'dados_seguro',
  DADOS_DECLARACOES = 'dados_declaracoes',
  DADOS_PAGAMENTO = 'dados_pagamento',
  DADOS_FINANCEIROS = 'dados_financeiros',
  AGUARDANDO_PAGAMENTO = 'aguardando_pagamento',
  DADOS_CREDITO = 'dados_credito',
  PROPONENTES = 'proponentes',
}

export enum FollowUpEnum {
  AGUARDANDO_PAGAMENTO = 'aguardando_pagamento',
  PAGAMENTO_NAO_AUTORIZADO = 'pagamento_nao_autorizado',
  PAGAMENTO_NAO_EFETUADO = 'pagamento_nao_efetuado',
  PAGAMENTO_CONCLUIDO = 'pagamento_concluido',
  COTA_CANCELADA = 'cota_cancelada',
  COTA_TRANSFERIDA = 'cota_transferida',
  ANALISE_CREDITO = 'credit_analysis',
  INTEGRACAO_NEWCON = 'integracao_newcon',
  VISTORIA = 'property_inspection_legal_analisys',
}

export enum HomeEquityEnum {
  DOCUMENTOS = 'under-analysis-data',
  VISTORIA = 'property_inspection_legal_analisys',
  ANALISE = 'credit_analysis',
  EMISSAO_CONTRATO = 'contract_issued',
  ASSINATURA = 'contract_signature',
  LIBERACAO = 'resources_released',
  CREDITO = 'credit_approved',
  NEGADO = 'declined',
  CANCELADO = 'cancelled',
  FINALIZADO = 'done',
  APROVADO = 'approved',
}

export enum TypeGroup {
  EmAndamento = 'A',
  EmFormacao = 'F',
  Ambos = 'T',
}

export enum TypeGroupName {
  'A' = 'Em andamento',
  'F' = 'Em formação',
  'T' = 'Ambos',
}

export const TypeGroupToDescription = (groupType: string) => {
  switch (groupType) {
    case 'A':
      return TypeGroupName.A
    case 'F':
      return TypeGroupName.F
    case 'T':
      return TypeGroupName.T
    default:
      return ''
  }
}

export enum NegotiationType {
  Offer = 'offer',
  Deal = 'deal',
}

export type ProductParams = {
  product: string
}

export const getFollowUpEnumNames = (pipe: any) => {
  switch (pipe || '') {
    case FollowUpEnum?.AGUARDANDO_PAGAMENTO:
      return 'Aguardando pagamento'
    case FollowUpEnum?.PAGAMENTO_NAO_AUTORIZADO:
      return 'Pagamento não autorizado'
    case FollowUpEnum?.PAGAMENTO_NAO_EFETUADO:
      return 'Pagamento não efetuado'
    case FollowUpEnum?.PAGAMENTO_CONCLUIDO:
      return 'Pagamento concluido'
    case FollowUpEnum?.COTA_CANCELADA:
      return 'Cota cancelada'
    case FollowUpEnum?.COTA_TRANSFERIDA:
      return 'Cota transferida'
    case FollowUpEnum?.ANALISE_CREDITO:
      return 'Análise de crédito'
    case FollowUpEnum?.INTEGRACAO_NEWCON:
      return 'Aguardando pagamento'
  }
}
