import axios from 'axios'
import { Dispatch } from 'redux'
import Cookies from 'universal-cookie'

import { getUrlBifrost } from 'services/environment'
import { axiosBifrost } from 'services/axiosInstance'

import { IApplicationState } from '..'
import { SessionTypes } from './types'
import { SessionCredentials } from 'types/Session'

export const login = (credentials: SessionCredentials) => {
  return async (dispatch: Dispatch, state: () => IApplicationState) => {
    try {
      const { data } = await axios.post(`${getUrlBifrost()}/users/v2/login?origin=avaweb`, {
        identifier: credentials.matricula,
        password: credentials.senha,
      })

      const cookies = new Cookies()
      cookies.set('AVA_SESSION_TOKEN', data.token)

      let headers = {}

      if (data.token) {
        headers = {
          Authorization: `Bearer ${data.token}`,
        }
      }

      const userResponse = await axiosBifrost.get(`${getUrlBifrost()}/users/me`, {
        headers,
      })

      localStorage.setItem('AVA_SESSION', JSON.stringify(userResponse.data))
      localStorage.setItem('AVA_SESSION_TOKEN', data.token)
      dispatch({ type: SessionTypes.STORE_USER_INFOS, payload: userResponse.data })
      dispatch({ type: SessionTypes.STORE_SESSION, payload: data.token })
    } catch (err: any) {
      throw Error(err.response.data.message)
    }
  }
}

export const userInfos = (token?: string) => {
  return async (dispatch: Dispatch, state: () => IApplicationState) => {
    let headers = {}

    if (token) {
      headers = {
        Authorization: `Bearer ${token}`,
      }
    }

    const { data } = await axiosBifrost.get(`${getUrlBifrost()}/users/me`, {
      headers,
    })
    dispatch({ type: SessionTypes.STORE_USER_INFOS, payload: data })
  }
}

export const setToken = (token: string) => {
  return async (dispatch: Dispatch, state: () => IApplicationState) => {
    dispatch({ type: SessionTypes.STORE_SESSION, payload: token })
  }
}

export const logout = () => {
  return async (dispatch: Dispatch, state: () => IApplicationState) => {
    const cookies = new Cookies()
    cookies.remove('AVA_SESSION_TOKEN')
    localStorage.clear()
    dispatch({ type: SessionTypes.STORE_SESSION, payload: '' })
  }
}
