import { LancesTypes, Ilances } from './types'
import { ISharedActions } from '../../utils/shared.types'

export const initialState: Ilances = {}

export const lancesReducer = (state: Ilances = initialState, action: ISharedActions): Ilances => {
  switch (action.type) {
    case LancesTypes.GET_DADOS_COTA:
      return {
        ...state,
        dadosCota: action.payload,
        loading: action.loading,
        nextStep: action.nextStep,
        error: action.error,
        loadingComprovante: undefined,
      }
    case LancesTypes.GET_CREDENCIAMENTO:
      return {
        ...state,
        dadosCredenciamento: action.payload,
        loading: action.loading,
        error: action.error,
        loadingComprovante: undefined,
      }
    case LancesTypes.SET_CREDENCIAR:
      return {
        ...state,
        credenciamento: action.payload,
        nextStep: action.nextStep,
        loading: action.loading,
        error: action.error,
        loadingComprovante: undefined,
      }
    case LancesTypes.SET_CANCELAR_LANCE:
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        loadingComprovante: undefined,
      }
    case LancesTypes.RELOAD_HIST:
      return {
        ...state,
        dadosCredenciamento: action.payload,
        loading: action.loading,
        error: action.error,
        loadingComprovante: undefined,
      }
    case LancesTypes.GET_COMPROVANTE:
      return {
        ...state,
        comprovante: action.payload,
        loadingComprovante: action.loading,
        error: action.error,
      }
    case LancesTypes.CLEAN_COMPROVANTE:
      return {
        ...state,
        comprovante: action.payload,
      }
    default:
      return state
  }
}
