import { ISharedActions } from 'utils/shared.types'
import { IInadimplenciaState, InadimplenciaTypes } from './types'

export const initialState: IInadimplenciaState = {
  loading: false,
  data: { total: 0, clientes: [] },
}

export const inadimplenciaReducer = (state = initialState, action: ISharedActions): IInadimplenciaState => {
  switch (action.type) {
    case InadimplenciaTypes.GET_INADIMPLENCIA_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      }
    case InadimplenciaTypes.GET_INADIMPLENCIA:
      return {
        ...action.payload,
      }
    default:
      return state
  }
}
