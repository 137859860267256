import { SimulationProduct } from 'types/Product'

export const ProductsTypes = {
  STORE_PRODUCTS: '@@products/STORE_PRODUCTS',
  STORE_CURRENT_SIMULATION: '@@products/STORE_CURRENT_SIMULATION',
}

export interface IProductsState {
  products?: IProducts[]
  currentSimulation?: SimulationProduct
  loading?: false
}

export interface IProducts {
  _id: string
  enabled: Boolean
  name: string
  createdAt: string
  updatedAt: string
  __v: Number
  tenant: string
}
