import { UserTypes, IUser } from './types'
import { ISharedActions } from '../../utils/shared.types'

export const initialState: IUser = {
  name: '',
  list: []
}

export const userReducer = (
  state: IUser = initialState,
  action: ISharedActions,
): IUser => {
  switch (action.type) {
    case UserTypes.GET_USER_NAME:
      return {
        ...state,
        list: action.payload
      }
    case UserTypes.UPDATE_USER_NAME:
      return {
        ...state
      }
    default:
      return state
  }
}
