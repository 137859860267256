import { Units, Types, File, User, List, UserInfo, Historic } from 'types/Previas'

export const PreviasTypes = {
  UPLOAD_FILE: '@@previas/UPLOAD_FILE',
  GET_SERVICES_UNITS: '@@previas/GET_SERVICES_UNITS',
  CREATE_PREVIA: '@@previas/CREATE_PREVIA',
  CREATE_USER: '@@previas/CREATE_USER',
  GET_DOCTYPE: '@@previas/GET_DOCTYPE',
  RESET_STATE: '@@previas/RESET_STATE',
  GET_LIST: '@@previas/GET_LIST',
  GET_USERS_DETAIL: '@@previas/GET_USERS_DETAIL',
  GET_USERS_DETAIL_BY_CPF: '@@previas/GET_USERS_DETAIL_BY_CPF',
  CREATE_PJ: '@@previas/CREATE_PJ',
  GET_HISTORIC: '@@previas/GET_HISTORIC',
  SEND_COMMENT: '@@previas/SEND_COMMENT',
  GET_DOCS: '@@previas/GET_DOCS',
  REFRESH_DOCS: '@@previas/REFRESH_DOCS',
  GET_PREVIA_BY_ID: '@@previas/GET_PREVIA_BY_ID',
  SET_DESISTIR: '@@previas/SET_DESISTIR',
  SET_MOTIVOS_PADRAO: '@@previas/SET_MOTIVOS_PADRAO',
}

export interface Iprevias {
  units?: Units[]
  docTypes?: Types[]
  previaById?: any
  loadingPreviaById?: boolean | null
  loading?: boolean
  loadingPrevias?: boolean
  success?: boolean | null
  successDoc?: boolean | null
  loadingRefresh?: boolean | null
  file?: File[]
  user?: User
  list?: List[]
  usersDetail?: UserInfo[]
  userDetailByCpf: any
  historic?: Historic[]
  docs?: any
  motivosPadrao?: any
}
