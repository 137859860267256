import { OfferTypes, IOffer } from './types'
import { ISharedActions } from '../../utils/shared.types'

export const initialState: IOffer = {}

export const offerReducer = (state: IOffer = initialState, action: ISharedActions): IOffer => {
  switch (action.type) {
    case OfferTypes.STORE_OFFER:
      return {
        ...state,
        currentOffer: action.payload,
      }
    default:
      return state
  }
}
