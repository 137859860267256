import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import Ui from 'components/app-navbar/app-navbar.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'

type AppNavbarItemProps = {
  icon?: any
  svg?: any
  label: String
  subItems?: any[]
  onClick?: any
}

const AppNavbarItem: React.FC<RouteComponentProps & AppNavbarItemProps> = ({
  icon,
  label,
  svg,
  subItems = [],
  onClick,
}) => {
  const [subItemsOpen, setSubItemsOpen] = React.useState<boolean>(false)

  return (
    <Ui.NavItem className="nav-item">
      <Ui.NavItemWrap
        onClick={() => {
          if (subItems.length > 0) {
            setSubItemsOpen(!subItemsOpen)
            return
          }

          if (onClick) onClick()
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Ui.NavItemIcon>
            {icon && <FontAwesomeIcon icon={icon} />}
            {svg && <img src={svg} alt={''} />}
          </Ui.NavItemIcon>
          <span>{label}</span>
        </div>
        {subItems.length > 0 && (
          <Ui.NavItemArrow>
            <FontAwesomeIcon icon={!subItemsOpen ? faChevronDown : faChevronUp} size="xs" />
          </Ui.NavItemArrow>
        )}
      </Ui.NavItemWrap>
      <Ui.NavSubItem open={subItemsOpen}>{subItems.length > 0 && subItems.map((e) => e)}</Ui.NavSubItem>
    </Ui.NavItem>
  )
}

export default withRouter(AppNavbarItem)
