import React, { lazy, Suspense, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { HashRouter, Switch, Route } from 'react-router-dom'
import { getProducts } from 'store/products/actions'
import PrivateRoute from './private-route'
import PageBaseContainer from 'components/base-page-container'
import { setToken, userInfos } from 'store/session/actions'

const Home = lazy(() => import('../pages/Home'))
const SignUp = lazy(() => import('../pages/SignUp'))
const FreeSimulation = lazy(() => import('../pages/FreeSimulation'))
const StartAttendance = lazy(() => import('../pages/StartAttendance'))
const HomeEquitySimulation = lazy(() => import('../pages/HomeEquitySimulation'))
const SelectQuota = lazy(() => import('../pages/SelectQuota'))
const FillProposal = lazy(() => import('../pages/FillProposal'))
const FillProposalHomeEquity = lazy(() => import('../pages/FillProposalHomeEquity'))
const StartAttendanceHE = lazy(() => import('../pages/StartAttendanceHE'))
const SMSVerificationPage = lazy(() => import('../pages/SMSVerification'))
const CreditSearch = lazy(() => import('../pages/CreditSearch'))
const HomeEquity = lazy(() => import('../pages/Products/HomeEquity'))
const ConsorcioImovel = lazy(() => import('../pages/Products/ConsorcioImovel'))
const ConsorcioServicos = lazy(() => import('../pages/Products/ConsorcioServicos'))
const ConsorcioDetail = lazy(() => import('../pages/Products/ConsorcioDetail'))
const Assembleia = lazy(() => import('../pages/Assembleia'))

const UserDetail = lazy(() => import('../pages/UserDetail'))
const UserOptions = lazy(() => import('../pages/UserDetail/UserOptions'))
const CandidateList = lazy(() => import('../pages/UserDetail/CandidateList'))

const Configuration = lazy(() => import('../pages/UserConfigs/Configurations'))
const Messages = lazy(() => import('../pages/UserConfigs/Messages'))

const Negociar = lazy(() => import('../pages/Negociar'))
const Inadimplencia = lazy(() => import('../pages/Inadimplencia'))

//lances
const Lances = lazy(() => import('../pages/Lances'))

const Routes: React.FC = () => {
  const dispatch = useDispatch()
  const token = localStorage.getItem('AVA_SESSION_TOKEN')

  useEffect(() => {
    if (token !== null) {
      dispatch(setToken(token))
      dispatch(userInfos(token))
      dispatch(getProducts())
    }
    // eslint-disable-next-line
  }, [token])

  return (
    <HashRouter>
      <Suspense fallback={<PageBaseContainer>{''}</PageBaseContainer>}>
        <Route path="/login" exact component={SignUp} />
        <Switch>
          <PrivateRoute path="/" exact component={Home} />
          <PrivateRoute path="/simulador/:product" exact component={FreeSimulation} />
          <PrivateRoute path="/atendimento/:product" exact component={StartAttendance} />
          <PrivateRoute path="/assembleia/:product" exact component={Assembleia} />
          <PrivateRoute path="/atendimento-he" exact component={StartAttendanceHE} />
          <PrivateRoute path="/simulador-he" exact component={HomeEquitySimulation} />
          <PrivateRoute path="/configs" exact component={Configuration} />
          <PrivateRoute path="/messages" exact component={Messages} />
          <PrivateRoute path="/selecionar-cota/:product/:id" exact component={SelectQuota} />
          <PrivateRoute path="/preencher-proposta/:product/:id" exact component={FillProposal} />
          <PrivateRoute path="/preencher-proposta-he" exact component={FillProposalHomeEquity} />
          <PrivateRoute path="/sms" exact component={SMSVerificationPage} />
          <PrivateRoute path="/buscar-credito/:product" exact component={CreditSearch} />
          <PrivateRoute path="/homeEquity-detail" exact component={HomeEquity} />
          <PrivateRoute path="/imoveis-detail/:state" exact component={ConsorcioImovel} />
          <PrivateRoute path="/imoveis-detail/:product/:id" exact component={ConsorcioDetail} />
          <PrivateRoute path="/servicos-detail" exact component={ConsorcioServicos} />
          <PrivateRoute path="/servicos-detail/:product/:id" exact component={ConsorcioDetail} />
          <PrivateRoute path="/negociar" exact component={Negociar} />
          <PrivateRoute path="/user-detail" exact component={UserDetail} />
          <PrivateRoute path="/user-options/:route/:status/:id/:hasTabs" exact component={UserOptions} />
          <PrivateRoute path="/candidate-list" exact component={CandidateList} />
          <PrivateRoute path="/inadimplencia" exact component={Inadimplencia} />
          <PrivateRoute path="/lances" exact component={Lances} />
        </Switch>
      </Suspense>
    </HashRouter>
  )
}

export default Routes
