import { PeopleTypes, IPeople } from './types'
import { ISharedActions } from '../../utils/shared.types'

export const initialState: IPeople = {}

export const peopleReducer = (state: IPeople = initialState, action: ISharedActions): IPeople => {
  switch (action.type) {
    case PeopleTypes.GET_PEOPLE:
      return {
        ...state,
        people: action.payload,
      }
    default:
      return state
  }
}
