import { Deal, ProposalResponse } from 'types/Deal'

export const DealTypes = {
  STORE_DEAL: '@@deal/STORE_DEAL',
  STORE_PROPOSAL: '@@deal/STORE_PROPOSAL',
}

export interface IDeal {
  currentDeal?: Deal
  proposal?: ProposalResponse
}
