import {
  faCalculator,
  faCoins,
  faDoorOpen,
  faHouse,
  faSearchDollar,
  faUsers,
  faGavel,
  faChartUser,
} from '@fortawesome/pro-light-svg-icons'
import AppNavbarItem from 'components/app-navbar-item'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../store/session/actions'
import Ui from './app-navbar.styled'
import LarIcon from 'assets/lar-white-icon.svg'
import List from 'assets/list-icon.svg'
import UseTie from 'assets/use-tie-icon.svg'
import ServicosIcon from 'assets/servicos-white-icon.svg'
import { Product } from 'enums'
import { clearCotasList } from 'store/followUp/actions'
import { socket } from 'routes/private-route'

import { IApplicationState } from 'store'
import { ISession } from 'store/session/types'

const AppNavbar: React.FC<RouteComponentProps & any> = ({ history, open = false }) => {
  const dispatch = useDispatch()
  const { user } = useSelector<IApplicationState, ISession>((state) => state.session)

  const _onLogout = async () => {
    dispatch(logout())
    socket.disconnect()
    history.push('/login')
  }

  const cleanAndGo = async (url: string) => {
    dispatch(clearCotasList())
    history.push(url)
  }

  return (
    <Ui.Nav open={open}>
      <div>
        <AppNavbarItem icon={faHouse} label="Início" onClick={() => history.push('/')} />
        <AppNavbarItem
          icon={faCoins}
          subItems={[
            <AppNavbarItem
              key="SLAR"
              svg={LarIcon}
              label="Imóveis"
              onClick={() => history.push(`/atendimento/${Product.Lar}`)}
            />,
            <AppNavbarItem
              key="SSER"
              svg={ServicosIcon}
              label="Serviços"
              onClick={() => history.push(`/atendimento/${Product.Servicos}`)}
            />,
          ]}
          label="Vender"
          onClick={() => history.push('/negociar')}
        />
        <AppNavbarItem
          icon={faCalculator}
          label="Simular"
          subItems={[
            <AppNavbarItem
              key="SLAR"
              svg={LarIcon}
              label="Imóveis"
              onClick={() => history.push(`/simulador/${Product.Lar}`)}
            />,
            <AppNavbarItem
              key="SSER"
              svg={ServicosIcon}
              label="Serviços"
              onClick={() => history.push(`/simulador/${Product.Servicos}`)}
            />,
          ]}
        />
        <AppNavbarItem
          icon={faUsers}
          label="Assembleias"
          subItems={[
            <AppNavbarItem
              key="LAR"
              svg={LarIcon}
              label="Imóveis"
              onClick={() => {
                cleanAndGo(`/assembleia/${Product.Lar}`)
              }}
            />,
            <AppNavbarItem
              key="SER"
              svg={ServicosIcon}
              label="Serviços"
              onClick={() => {
                cleanAndGo(`/assembleia/${Product.Servicos}`)
              }}
            />,
          ]}
        />
        <AppNavbarItem
          icon={faSearchDollar}
          label="Buscar Créditos"
          subItems={[
            <AppNavbarItem
              key="LAR"
              svg={LarIcon}
              label="Imóveis"
              onClick={() => cleanAndGo(`/buscar-credito/${Product.Lar}`)}
            />,
            <AppNavbarItem
              key="SER"
              svg={ServicosIcon}
              label="Serviços"
              onClick={() => cleanAndGo(`/buscar-credito/${Product.Servicos}`)}
            />,
          ]}
        />
        <AppNavbarItem icon={faGavel} label="Oferta de Lance" onClick={() => history.push('/lances')} />
        {user?.seePreviaCard && (
          <AppNavbarItem
            icon={faChartUser}
            label="Prévias"
            subItems={[
              <AppNavbarItem
                key="ficha"
                svg={UseTie}
                label="Ficha cadastral"
                onClick={() => {
                  history.push({
                    pathname: '/user-options/0/false/0/false',
                    state: { hasTabs: false, route: undefined },
                  })
                }}
              />,
              <AppNavbarItem
                key="lista"
                svg={List}
                label="Lista de candidatos"
                onClick={() => {
                  history.push('/candidate-list')
                }}
              />,
            ]}
          />
        )}
        {/* <AppNavbarItem
          icon={faChartUser}
          label="Prévias"
          subItems={[
            <AppNavbarItem
              key="Ficha cadastral de candidato"
              icon={faUserTie}
              label="Ficha cadastral de candidato"
              onClick={() => cleanAndGo(`/user-options/0/false/0/false`)}
            />,
            <AppNavbarItem
              key="Acompanhar candidato"
              icon={faList}
              label="Acompanhar candidato"
              onClick={() => cleanAndGo(`/candidate-list`)}
            />,
          ]}
        /> */}
      </div>
      <AppNavbarItem icon={faDoorOpen} label="Sair" onClick={_onLogout} />
    </Ui.Nav>
  )
}

export default withRouter(AppNavbar)
