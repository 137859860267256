import { ScreenSize } from 'shared/theme/theme'
import styled from 'styled-components'

const pageWrapStyle = {
  Wrap: styled.div`
    height: 100%;
    min-height: 100vh;
    max-width: ${ScreenSize.max}px;
    min-width: ${ScreenSize.min}px;
    margin: 0 auto;
    position: relative;
  `,
}

export default pageWrapStyle
