import { HomeEquityTypes, IHomeEquity } from './types'
import { ISharedActions } from '../../utils/shared.types'

export const initialState: IHomeEquity = {}

export const homeEquityReducer = (state: IHomeEquity = initialState, action: ISharedActions): IHomeEquity => {
  switch (action.type) {
    case HomeEquityTypes.STORE_SIMULATION:
      return {
        ...state,
        currentSimulation: action.payload,
      }
    case HomeEquityTypes.STORE_SIMULATION_DATA:
      return {
        ...state,
        simulationData: action.payload
      }
    default:
      return state
  }
}
