import { AppColors, FontWeight, Spacing } from 'shared/theme/theme'
import styled from 'styled-components'

const appHeaderStyle = {
  Wrap: styled.header`
    background: #333333;
    color: ${AppColors.white};
    display: flex;
    justify-content: space-between;
  `,
  Info: styled.div`
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.5);
    padding: ${Spacing.md}px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    background: #454545;
  `,
  MenuButton: styled.button<any>`
    border: 0;
    background: none;
    font-size: 21px;
    padding: 0;
    margin-right: 45px;
    margin-left: 20px;
    color: ${AppColors.white};
  `,
  Logo: styled.div`
    margin-right: 30px;
    cursor: pointer;
  `,
  Divider: styled.div`
    width: 89%;
    height: 1px;
    background: ${AppColors.white};
    margin: 0 auto;
  `,
  DividerContainer: styled.div`
    background: #454545;
  `,
  Welcome: styled.div`
    margin-left: 30px;
    font-weight: ${FontWeight.bold};
  `,
  TextSettings: styled.div`
    margin-left: 30px;
    font-size: 16px;
    color: #333333;
    font-weight: ${FontWeight.semibold};
  `,
  TextSettingsSub: styled.div`
    margin-left: 30px;
    font-size: 14px;
    color: #59595b;
    font-weight: ${FontWeight.regular};
  `,
  Actions: styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
  `,
  ActionButtons: styled.button`
    border: 0;
    background: none;
    font-size: 21px;
    padding: 0;
    color: ${AppColors.white};
    margin-left: ${Spacing.md}px;
  `,
  Arrow: styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    border-bottom: 8px solid white;
    right: 16px;
    top: 63.5px;
  `,
  Content: styled.div`
    width: 363px;
    background-color: white;
    position: absolute;
    right: 16px;
    border-color: #939598;
    border-width: 0.5px;
    border-style: solid;
    border-radius: 3px;
    top: 80px;
    border-top-color: transparent;
    padding-bottom: 26px;
    z-index: 999;
  `,
  Items: styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%;
  `,
  TextsHelper: styled.div`
    flex-direction: column;
    margin-left: -16px;
    display: inline-block;
    cursor: pointer;
  `,
}

export default appHeaderStyle
