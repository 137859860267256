import { INegociar, NegociarTypes } from './types'
import { ISharedActions } from '../../utils/shared.types'

export const initialState: INegociar = {}

export const negociarReducer = (state: INegociar = initialState, action: ISharedActions): INegociar => {
  switch (action.type) {
    case NegociarTypes.LOADING_DETAIL_INFO:
      return {
        ...state,
        loading: true,
      }
    case NegociarTypes.GET_NEGOCIACOES:
      return {
        ...state,
        loading: false,
        negociacoes: action.payload,
      }
    case NegociarTypes.GET_ITEMS:
      return {
        ...state,
        items: action.payload,
        loading: false,
      }
    case NegociarTypes.GET_DETAIL:
      return {
        ...state,
        detail: action.payload,
        loading: false,
      }
    case NegociarTypes.GET_DETAIL_INFO:
      return {
        ...state,
        detailInfo: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
