import logger from 'redux-logger'
import { Store, applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { rootReducer } from './rootReducer'
import { IUser } from './user/types'
import { ISession } from './session/types'
import { IProductsState } from './products/types'
import { IDeal } from './deal/types'
import { IOffer } from './offer/types'
import { IHomeEquity } from './homeEquity/types'
import { IFollowUp } from './followUp/types'
import { INegociar } from './negociar/types'
import { Iprevias } from './previas/types'
import { IPeople } from './people/types'
import { IInadimplenciaState } from './inadimplencia/types'
import { Ilances } from './lances/types'
export const history = createBrowserHistory()

export interface IApplicationState {
  user: IUser
  session: ISession
  products: IProductsState
  deal: IDeal
  offer: IOffer
  homeEquity: IHomeEquity
  followUp: IFollowUp
  negociar: INegociar
  previas: Iprevias
  people: IPeople
  inadimplencia: IInadimplenciaState
  lances: Ilances
}

export default function configureStore(initialState: IApplicationState, showLogger = true): Store<IApplicationState> {
  const includeLogger = showLogger && process.env.NODE_ENV === 'development' ? [logger] : []
  const middlewares = [...includeLogger, thunk]
  const store = createStore(rootReducer(), initialState, compose(applyMiddleware(...middlewares)))

  return store
}
