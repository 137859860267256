import React from 'react'
import styled from 'styled-components'

interface AlignProps {
  alignment: Alignment
  direction?: Direction
  width?: string
  height?: string
}

export enum Alignment {
  start,
  center,
  end,
}

export enum Direction {
  vertical,
  horizontal,
}

const ui = {
  align: styled.div<any>`
    width: ${({ width }) => width ?? ''};
    height: ${({ height }) => height ?? ''};
    display: flex;
    flex-direction: ${({ direction }: AlignProps) => {
      if (direction === undefined) return ''
      return direction === Direction.horizontal ? 'row' : 'column'
    }};
    justify-content: ${({ alignment }: AlignProps) => {
      switch (alignment) {
        case Alignment.start:
          return 'flex-start'
        case Alignment.end:
          return 'flex-end'
        case Alignment.center:
          return 'center'
        default:
          return ''
      }
    }};
  `,
}

/**
 * @param Alignment Define o alinhamento do filho
 * @param Direction Define a direção do alinhamento do filho
 **/
const Align: React.FC<AlignProps> = ({ children, alignment, direction, ...props }) => {
  return (
    <ui.align alignment={alignment} direction={direction} {...props}>
      {children}
    </ui.align>
  )
}

export default Align
