import { axiosApi } from 'services/axiosInstance'
import { Dispatch } from 'redux'
import { getTenantId } from 'services/environment'

import { IApplicationState } from '..'
import { FollowUpTypes } from './types'
export interface AssembleiaHeader {
  CD_Grupo: string
  ID_Grupo: string
}

export interface AssembleiaDetailHeader {
  CD_Grupo: string
  Numero_Assembleia: string
}

export const getFollowUp = (product: String) => {
  return async (dispatch: Dispatch, state: () => IApplicationState) => {
    const { data } = await axiosApi.get<any>(`products/${product}/track`)
    dispatch({ type: FollowUpTypes.STORE_FOLLOWUP, payload: data })
  }
}

export const getAcompanhamento = () => {
  return async (dispatch: Dispatch, state: () => IApplicationState) => {
    dispatch({ type: FollowUpTypes.BEGIN_FETCH })
    const { data } = await axiosApi.get<any>(`/offers/sells`)
    dispatch({ type: FollowUpTypes.STORE_ACOMPANHAMENTO, payload: data })
  }
}

export const getCotasList = (groupId: String) => {
  return async (dispatch: Dispatch, state: () => IApplicationState) => {
    const { data } = await axiosApi.get<any>(`tenants/${getTenantId()}/creditos/grupos/${groupId}/todos`)
    dispatch({ type: FollowUpTypes.STORE_COTAS_FOLLOWUP, payload: data })
  }
}

export const getAssembleiaList = (dataAssembleia: AssembleiaHeader) => {
  return async (dispatch: Dispatch, state: () => IApplicationState) => {
    const { data } = await axiosApi.post<any>(`newconsv/assembleiasService/calendario_grupo`, dataAssembleia)
    dispatch({ type: FollowUpTypes.STORE_ASSEMBLEIA_FOLLOWUP, payload: data })
  }
}

export const getAssembleiaDetail = (dataAssembleia: AssembleiaDetailHeader) => {
  return async (dispatch: Dispatch, state: () => IApplicationState) => {
    const { data } = await axiosApi.post<any>(`newconsv/assembleiasService/resultado_assembleia`, dataAssembleia)
    dispatch({ type: FollowUpTypes.STORE_ASSEMBLEIA_DETAIL_FOLLOWUP, payload: data })
  }
}

export const clearCotasList = () => {
  return async (dispatch: Dispatch, state: () => IApplicationState) => {
    dispatch({ type: FollowUpTypes.CLEAR_COTAS_LIST, payload: undefined })
  }
}
